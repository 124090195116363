import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { usePageType } from '@context/PageTypeContext';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { useColorByBackground, useHeaderModeByBackground } from '@hooks/helpers';
import VisibilitySensor from '@c/VisibilitySensor';
import { getHeroParams } from './Hero.helpers';
import StyledHero from './Hero.styled';

const Hero = ({
  backgroundColor,
  overlayOpacity,
  overlayColor: overlayColorProp,
  typeHandle,
  image,
  video,
  text,
  fullHeight,
  ...props
}) => {
  const pageType = usePageType(),
    theme = useTheme(),
    { gridContainerProps, gridItemProps } = getHeroParams(pageType),
    overlayColor = overlayColorProp || theme.colors.black,
    overlay = !!overlayOpacity &&
      !!overlayColor && { color: overlayColor, opacity: overlayOpacity },
    [{ header }, setBackground] = useColorMode(),
    headerMode = useHeaderModeByBackground(backgroundColor || overlayColor),
    color = useColorByBackground(backgroundColor || overlayColor);

  const handleOnVisible = useCallback(
    id => {
      switch (typeHandle) {
        case 'heroColor':
          setBackground({
            type: SET_COLOR_MODE,
            header: { mode: headerMode },
            background: { color: backgroundColor },
            visibility: {
              hiddenContentVisible: false,
              visibleContentId: id,
            },
          });
          break;
        case 'heroImage':
          setBackground({
            type: SET_COLOR_MODE,
            header: { mode: headerMode },
            background: {
              color: backgroundColor,
              overlay,
              image: image[0],
              altText: image[0].title,
            },
            visibility: {
              hiddenContentVisible: false,
              visibleContentId: id,
            },
          });
          break;
        case 'heroVideo':
          setBackground({
            type: SET_COLOR_MODE,
            header: { mode: headerMode },
            background: {
              color: backgroundColor,
              overlay,
              video,
            },
            visibility: {
              hiddenContentVisible: false,
              visibleContentId: id,
            },
          });
          break;
        default:
          return;
      }
    },
    [headerMode, image, setBackground, backgroundColor, overlay, typeHandle, video]
  );

  return (
    <VisibilitySensor
      id='hero'
      offsetTop
      onVisible={handleOnVisible}
      forceHiddenInactiveState
      disabled={!fullHeight}
    >
      <StyledHero
        fullHeight={fullHeight}
        headerAbsolute={header.absolute}
        color={color}
        bgColor={backgroundColor}
        overlay={overlay}
        bgImage={image && image[0]}
      >
        <Container>
          <Grid container {...gridContainerProps}>
            <Grid item {...gridItemProps} dangerouslySetInnerHTML={{ __html: text }} {...props} />
          </Grid>
        </Container>
      </StyledHero>
    </VisibilitySensor>
  );
};

Hero.propTypes = {
  backgroundColor: PropTypes.string,
  overlayOpacity: PropTypes.string,
  overlayColor: PropTypes.string,
  typeHandle: PropTypes.oneOf(['heroColor', 'heroImage', 'heroVideo']).isRequired,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  video: PropTypes.string,
  text: PropTypes.string.isRequired,
  fullHeight: PropTypes.bool,
};

Hero.defaultProps = {
  backgroundColor: null,
  overlayOpacity: null,
  overlayColor: null,
  image: null,
  video: null,
  fullHeight: false,
};

export default Hero;
