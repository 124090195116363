import { PAGE_TYPE_LAYOUT, PAGE_TYPE_CASE, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';

export const getHeroParams = pageType => {
  switch (pageType) {
    case PAGE_TYPE_LAYOUT:
      return {
        gridContainerProps: {},
        gridItemProps: {
          xs: 12,
          md: 10,
          lg: 8,
          xl: 7,
        },
      };
    case PAGE_TYPE_CASE:
      return {
        gridContainerProps: {
          justify: 'center',
        },
        gridItemProps: {
          xs: 12,
          md: 10,
          lg: 8,
        },
      };
    case PAGE_TYPE_ARTICLE:
      return {
        gridContainerProps: {
          justify: 'center',
        },
        gridItemProps: {
          xs: 12,
          md: 10,
          lg: 8,
        },
      };
    default:
      return {
        gridContainerProps: {},
        gridItemProps: {},
      };
  }
};
