import styled, { css } from 'styled-components';
import { palette, sizing } from '@material-ui/system';
import { transparentize } from 'polished';

const bgColor = css`
  transition: background-color 0.3s linear;
  background: ${props => props.bgColor};
`;

const overlay = css`
  position: relative;

  & > * {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.3s linear;
    background-color: ${({ overlay }) => transparentize(1 - overlay.opacity / 100, overlay.color)};
  }
`;

const bgImage = css`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${({ bgImage, theme }) =>
    bgImage.url &&
    `
    background-image: url(${bgImage.url});
  `}

  ${({ bgImage, theme }) =>
    bgImage.lgUrl &&
    `
    ${theme.breakpoints.down('lg')} {
      background-image: url(${bgImage.lgUrl});
    }
  `}

  ${({ bgImage, theme }) =>
    bgImage.mdUrl &&
    `
    ${theme.breakpoints.down('md')} {
      background-image: url(${bgImage.mdUrl});
    }
  `}

  ${({ bgImage, theme }) =>
    bgImage.smUrl &&
    `
    ${theme.breakpoints.down('sm')} {
      background-image: url(${bgImage.smUrl});
    }
  `}

  ${({ bgImage, theme }) =>
    bgImage.xsUrl &&
    `
    ${theme.breakpoints.down('xs')} {
      background-image: url(${bgImage.xsUrl});
    }
  `}
`;

const notFullHeight = css`
  padding-top: ${({ headerAbsolute, theme }) =>
    headerAbsolute
      ? `calc(${theme.spacing(18)}px + ${theme.header.height}px)`
      : `${theme.spacing(18)}px`};
  padding-bottom: ${({ theme }) => theme.spacing(29)}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: ${({ headerAbsolute, theme }) =>
      headerAbsolute
        ? `calc(${theme.spacing(11.5)}px + ${theme.header[theme.breakpoints.down('sm')].height}px)`
        : `${theme.spacing(11.5)}px`};
    padding-bottom: ${({ theme }) => theme.spacing(11.5)}px;
  }

  ${props => props.bgColor && bgColor}
  ${props => props.overlay && overlay}
  ${props => props.bgImage && bgImage}
`;

const fullHeight = css`
  padding: ${({ theme }) => theme.spacing(24.5)}px 0;
  height: ${({ theme }) => `calc(100vh - ${theme.header.height}px)`};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: ${({ theme }) =>
      `calc(100vh - ${theme.header[theme.breakpoints.down('sm')].height}px)`};
  }
`;

export default styled.div`
  ${palette}${sizing}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${props => (props.fullHeight ? fullHeight : notFullHeight)}

  h1, h2, h3 {
    font-size: 1.625rem;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 4.5vw;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      font-size: 3.56rem;
    }
  }

  p {
    font-size: 1.5rem;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: 1.0625rem;
    }
  }
`;
