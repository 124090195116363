import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Container, Grid, Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { PageTypeContextProvider, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import Hero from '@c/Hero';
import VisibilitySensor from '@c/VisibilitySensor';
import ArticleContent from '@c/ArticleContent';
import { getContentParams } from '@c/ArticleContent/ArticleContent.helpers';

const Page = ({
  data: {
    cms: { entries, seomatic },
  },
  pageContext: { slug },
}) => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    entry = entries[0],
    title = entry.title,
    showTitle = entry.showTitle,
    pageType = entry.pageStyle || PAGE_TYPE_ARTICLE,
    articleContent = entry.articleContent;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <PageTypeContextProvider value={pageType}>
      <EntryContextProvider entry={entry}>
        <Layout seomatic={seomatic}>
          {!!entry.hero && !!entry.hero.length && <Hero {...entry.hero[0]} />}

          {showTitle && (
            <VisibilitySensor id={`page-${slug}`} onVisible={handleOnVisible}>
              <Box mt={12}>
                <Container>
                  <Grid container {...getContentParams(pageType).gridContainerProps}>
                    <Grid item {...getContentParams(pageType).gridItemProps}>
                      <Typography variant='h1' component='h1'>
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </VisibilitySensor>
          )}

          <ArticleContent
            content={articleContent}
            mt={showTitle ? undefined : 12}
            mb={{ xs: 12, md: 19.5 }}
          />
        </Layout>
      </EntryContextProvider>
    </PageTypeContextProvider>
  );
};

export default Page;

export const query = graphql`
  query($slug: String!, $path: String!) {
    cms {
      entries(section: "pages", slug: [$slug]) {
        ... on cms_pages_page_Entry {
          title
          showTitle
          pageStyle
          hero {
            ...heroFields
          }
          articleContent {
            ...articleContentFields
          }
        }
      }
      seomatic(uri: $path) {
        ...seomaticFields
      }
    }
  }
`;
